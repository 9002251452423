* {
	font-family: 'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-smooth: always;
}
body {
	background-color: #333;
}
.home {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #333;
}

/*--------------------------------------ABOUT ME-----------------------------------------------------------------------*/

.AboutMe {
	background-color: #d8d3f9;
	color: #000;
	min-height: calc(100vh - (60px));
	padding: 2rem;
	padding-top: 3rem;
	padding-bottom: 5rem;
}

.AboutMe h1 {
	color: black !important;
	text-align: center;
	letter-spacing: 1px;
}

.about-grid {
	max-width: 90vw;
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	margin-top: 2rem;
}
.about-image {
	display: flex;
	justify-content: center;
	align-items: center;
}
.Potrait {
	border-radius: 5%;
	border: 2px solid #fff;
	width: 250px;
	height: 250px;
}
.about-content {
	padding: 2rem 0;
}
.about-content p {
	letter-spacing: 0.5px;
	line-height: 1.3;
	padding-bottom: 1rem;
	font-weight: 400;
}
.about-content span {
	font-weight: 500;
}
.AboutMe .connect {
	text-align: center;
	margin-top: 1rem;
}
.AboutMe .connect a {
	text-decoration: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0.5rem 1rem;
	border-radius: 4px;
	outline: none;
	background-color: transparent;
	color: rgb(0, 0, 0);
	border: 1px solid rgb(0, 0, 0);
	transition: all 0.2s ease;
	letter-spacing: 1px;
	font-size: 1rem;
}
.AboutMe .connect a:hover {
	background-color: transparent;
	background-color: #ff8533;
	color: black;
	border: 1px solid #ff8533;
	transition: all 0.2s ease;
}
@media only screen and (max-width: 1024px) {
	.about-grid {
		max-width: 97vw;
		display: grid;
		grid-template-columns: 1fr;
		margin-top: 2rem;
	}
}
@media only screen and (max-width: 600px) {
	.AboutMe h1 {
		font-size: 1.8rem;
		letter-spacing: 0.5px;
		font-weight: 600;
	}
	.MyWork h1 {
		font-size: 1.5rem;
		letter-spacing: 0.5px;
		font-weight: 600;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

/*--------------------------------------CONTACT ME---------------------------------------------------------------------*/

.ContactMe {
	background-color: #464362;
	color: #bebccd;
	min-height: calc(100vh - (60px));
	padding: 2rem;
	padding-top: 3rem;
}

.ContactMe h1 {
	color: #bebccd !important;
	letter-spacing: 1px;
	text-align: center;
	font-weight: 700;
}

.contact-page {
	box-shadow: 0px 7px 30px -5px rgba(216,219,249,0.4);
	max-width: 800px;
	margin: auto;
	margin-top: 3rem;
	margin-bottom: 3rem;
	border-radius: 5px;
	background-color: #464362;
}

.form-group {
	margin: auto;
	max-width: 600px;
	padding: 2rem 0rem;
	background-color: #464362;
}

.active {
	color: red;
}
@media only screen and (max-width: 750px) {
	.contact-page {
		max-width: 600px;
	}
	.form-group {
		margin: auto;
		max-width: 500px;
	}
}
@media only screen and (max-width: 600px) {
	.contact-page {
		max-width: 375px;
		box-shadow: none;
		margin-bottom: 0;
	}
	.form-group {
		margin: auto;
		max-width: 375px;
		padding: 1rem 0rem;
	}
	.ContactMe h1 {
		font-weight: 600;
		font-size: 1.9rem;
	}
	.ContactMe {
		min-height: calc(85vh - (60px));
	}
}

.input-div {
	position: relative;
	background-color: #464362;
	padding-top: 15px;
}
.form-group input {
	width: 100%;
	height: 100%;
	color: #cdbcbc;
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
	padding-top: 20px;
	padding-bottom: 5px;
	border: none;
	outline: none;
	background-color: #464362;
}

.form-group textarea {
	width: 100%;
	height: 100%;
	color: #bebccd;
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
	background-color: #464362;

	padding-top: 20px;
	padding-bottom: 5px;
	border: none;
	outline: none;
}
.form-group label {
	position: absolute;
	bottom: 0px;
	left: 0%;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-bottom: 2px solid #a5a2b5;
	overflow: hidden;
}
.form-group label::after {
	content: '';
	position: absolute;
	left: 0px;
	bottom: -1px;
	width: 100%;
	border-bottom: 2px solid #ddd;
	transform: translateX(-100%);
	transition: transform 0.5s ease;
}

.content-name {
	position: absolute;
	bottom: 10px;
	left: 0;
	transition: all 0.3s eases;
}
.text-area-content-name {
	position: absolute;
	bottom: 70px;
	left: 0;
	transition: all 0.3s eases;
}
.input-div input:focus + .label-name .content-name,
.input-div input:valid + .label-name .content-name {
	transform: translateY(-80%);
	font-size: 16px;
	color: #bebccd;
}
.input-div input:focus + .label-name::after,
.input-div input:valid + .label-name::after {
	transform: translateX(-0%);
}
.input-div textarea:focus + .label-name .text-area-content-name,
.input-div textarea:valid + .label-name .text-area-content-name {
	transform: translateY(-50%);
	font-size: 16px;
	color: #bebccd;
}
.input-div textarea:focus + .label-name::after,
.input-div textarea:valid + .label-name::after {
	transform: translateX(-0%);
}

.submit-btn {
	margin-top: 2.5rem;
	text-align: center;
}

.submit-btn button {
	text-decoration: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0.5rem 26px;
	border-radius: 4px;
	outline: none;
	background-color: transparent;
	color: #bebccd;
	border: 1px solid #bebccd;
	transition: all 0.2s ease;
	letter-spacing: 1px;
	font-size: 1rem;
}
.submit-btn button:hover {
	background-color: transparent;
	background-color: #ff8533;
	color: black;
	border: 1px solid #ff8533;
	transition: all 0.2s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: #bebccd;
	-webkit-box-shadow: 0 0 0px 1000px #464362 inset;
	transition: background-color 5000s ease-in-out 0s;
}

.ContactMe .errorMsg {
	color: rgb(255, 38, 38);
	letter-spacing: 0.5px;
	padding: 0;
	margin: 0;
	padding-bottom: 2px;
	font-size: 14px;
}
.ContactMe .submit-message {
	color: #55ba59;
	letter-spacing: 0.6px;
	padding-top: 1.5rem;
	padding-bottom: 1rem;
	font-size: 1.2rem;
	font-weight: 500;
}

.footer-container {
	background-color: #1b1919;
	color: #ddd;
	padding: 2.5rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.5px;
}
.footer-container a {
	color: #ddd;
	text-decoration: none;
}
.footer-container p {
	padding: 5px;
}

.footer-container .line {
	background-color: #444;
	height: 0.8px;
	width: 98vw;
	margin-bottom: 10px;
	margin-top: 2rem;
}
.footer-bottom {
	height: 5px;
	font-size: 12px;
	color: #eee;
}

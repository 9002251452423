.navbar {
	width: 100vw;
	background: transparent;
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 999;
}
.navbar.active {
	background: #1b1919;
}
.navbar-container {
	min-width: 90vw;
	display: flex;
	justify-content: space-between;
}

.navbar-container ul {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	justify-content: center;
	align-items: center;
	align-content: center;
}
.navbar-container ul li {
	list-style-type: none;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
}
.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	letter-spacing: 1px;
	transform: translate(0%, -5%);
}
.navbar-container .navbar-logo span {
	color: #fd8b3f;
}
.logo-png {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	margin-top: 5px;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	min-width: 50vw;
	justify-content: end;
	margin-right: 0;
}

.nav-links {
	color: #fff;
	text-decoration: none;
	transition: all, 200ms ease-in;
	cursor: pointer;
}

.nav-links:hover {
	color: #ff8533;
	transition: all, 200ms ease-in;
}

.fa-bars {
	color: #fff;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

.github-icon .nav-github {
	background-color: #222;
	color: #fff;
	font-size: 2rem;
	margin: 0;
	padding: 0;
	border-radius: 50%;
	overflow: hidden;
}
.github-icon .nav-github:hover {
	color: #ff8533;
	transition: all 0.2s ease-out;
}
@media screen and (max-width: 830px) {
	body {
		background: #242222;
	}
	.NavbarItems {
		position: relative;
	}
	.navbar {
		height: 80px;
	}
	.navbar-container ul {
		display: grid;
		grid-template-columns: 1fr;
		margin-right: 0rem;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 90vh;
		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 900;
	}

	.nav-menu.active {
		background: #242222;
		left: 0;
		top: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
		height: 100vh;
	}

	.nav-links {
		text-align: center;
		padding: 1.5rem;
		width: 100%;
		display: table;
	}

	.navbar-logo {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(5%, 30%);
	}

	.menu-icon {
		z-index: 2;
		display: block;
		position: absolute;
		top: 1.3rem;
		right: 2rem;
		cursor: pointer;
	}

	.menu-icon-bars {
		color: #fff;
		font-size: 2.3rem;
	}
	.menu-icon-close {
		font-size: 3rem;
		color: #fff;
		margin: 0;
		padding: 0;
		margin-top: -0.4rem;
		margin-right: -0.3rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		text-decoration: none;
		font-size: 1.5rem;
		background-color: transparent;
		color: #fff;
		padding: 14px 20px;
		border: 1px solid #fff;
		transition: all 0.3s ease-out;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #242424;
		transition: 250ms;
	}
	.github-icon {
		display: none;
	}
}

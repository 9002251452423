video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: -1;
	background-color: #333;
}

.hero-container {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	object-fit: contain;
}

.hero-container > h1 {
	text-align: center;
	color: #fff;
	font-size: 3rem;
	font-weight: 600;
	letter-spacing: 0.5px;
	margin-top: -50px;
}

.hero-container > p {
	margin-top: 10px;
	color: #fff;
	font-size: 1.5rem;
	letter-spacing: 0.5px;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
		'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
	margin-top: 32px;
}

.hero-btns .btn {
	margin: 6px;
}

.fa-play-circle {
	margin-left: 4px;
}

@media screen and (max-width: 960px) {
	.hero-container > h1 {
		font-size: 3rem;
		margin-top: -150px;
	}
}

@media screen and (max-width: 768px) {
	.hero-container > h1 {
		font-size: 40px;
		margin-top: -100px;
	}

	.hero-container > p {
		font-size: 20px;
	}

	.btn-mobile {
		display: block;
		text-decoration: none;
	}

	.btn {
		width: 100%;
	}
}
